/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it\
import "./src/styles/style.scss"

import $ from "jquery"
export const onInitialClientRender = () => {
  $(".c-loader__counter").each(function () {
    $(this).addClass("visible")
    $(this)
      .find("span")
      .prop("Counter", 0)
      .animate(
        {
          Counter: $(this).text(),
        },
        {
          duration: 3000,
          easing: "swing",
          step: function (now) {
            $(this).text(Math.ceil(now))
          },

          complete: function () {
            setTimeout(function () {
              $("#c-loader").addClass("completed")
            }, 1000)
            setTimeout(function () {
              $("body").addClass("content-loaded")
            }, 1000)
          },
        }
      )
  })

}
