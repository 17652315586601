import * as React from "react"
import TransitionLink from "gatsby-plugin-transition-link"
import { newContent, animateObjects } from "./layout"
import SvgSprite from "../components/parts/svg-sprite"
import { useLocation } from "@reach/router"

import { PAGE_WITHOUT_CTA } from "../shared/enums"

const Footer = () => {
  const location = useLocation()
  const path = location.pathname
  return (
    <footer className="c-footer">
      <div className="l-container">
        {!PAGE_WITHOUT_CTA.includes(path) && (
          <section className="c-cta">
            <div className="c-cta__content">
              <p className="c-cta__lead-text">Have a Project?</p>
              <h2 className="c-cta__title">let’s talk about it</h2>
            </div>
            <div className="c-cta__btn">
              <TransitionLink
                to="/contact/"
                className="c-btn c-btn--dark"
                exit={{
                  length: 0.6,
                  trigger: ({ exit, e, node }) => animateObjects(exit, node),
                }}
                entry={{
                  delay: 0.7,
                  trigger: ({ entry, node }) => newContent(node),
                }}
              >
                <svg className="icon icon-envelope">
                  <use xlinkHref="#icon-envelope"></use>
                </svg>
                <span></span>
                Send Us a Message
              </TransitionLink>
            </div>
          </section>
        )}
        <div className="c-footer__menu-wrap">
          <ul className="c-footer__menu">
            <li>
              <TransitionLink
                to="/"
                title="home"
                exit={{
                  length: 0.6,
                  trigger: ({ exit, e, node }) => animateObjects(exit, node),
                }}
                entry={{
                  delay: 0.7,
                  trigger: ({ entry, node }) => newContent(node),
                }}
              >
                home
              </TransitionLink>
            </li>
            <li>
              <TransitionLink
                to="/about/"
                title="about"
                exit={{
                  length: 0.6,
                  trigger: ({ exit, e, node }) => animateObjects(exit, node),
                }}
                entry={{
                  delay: 0.7,
                  trigger: ({ entry, node }) => newContent(node),
                }}
              >
                about
              </TransitionLink>
            </li>
            <li>
              <TransitionLink
                to="/contact/"
                title="contact"
                exit={{
                  length: 0.6,
                  trigger: ({ exit, e, node }) => animateObjects(exit, node),
                }}
                entry={{
                  delay: 0.7,
                  trigger: ({ entry, node }) => newContent(node),
                }}
              >
                contact
              </TransitionLink>
            </li>
          </ul>
        </div>
        <div className="c-footer__bottom">
          <p className="c-footer__copyright">
            &copy; {new Date().getFullYear()} idealaya
          </p>
          <p className="c-footer__contact">
            <a href="mailto:hello@idealaya.co">hello@idealaya.co</a>
          </p>
        </div>
      </div>
      <SvgSprite title="Svg Sprite" />
    </footer>
  )
}
export default Footer
